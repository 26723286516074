<template>
  <div class="klubba-checkbox">
    <label class="checkbox-wrap" :class="customClass">
      <input
        type="checkbox"
        :value="value"
        :checked="modelValue"
        @change="$emit('update:modelValue', $event.target.checked)"
      >
      <span class="checkmark">
        <img src="~@/assets/icons/tick-icon.svg" class="tick" />
      </span>
      <span class="label">{{ label }}</span>
    </label>
  </div>
</template>
<script>
export default {
  name: 'KlubbaCheckbox',
  props: ['modelValue', 'label', 'customClass', 'value'],
  emits: ['update:modelValue'],
};
</script>

<style lang="sass" scoped>
.klubba-checkbox
  & + &
    @apply mt-5

.checkbox-wrap
  @apply flex relative cursor-pointer text-base select-none items-center

.label
  @apply flex-1

input
  @apply absolute h-0 w-0

.checkmark
  @apply relative h-6.5 w-6.5 bg-white rounded-full border border-gray-light bg-primary mr-5 flex items-center justify-center
  transition: border-color var(--transition), background-color var(--transition)

.tick
  @apply block opacity-0
  transform: scale(.5)
  transition: opacity var(--transition), transform var(--transition)

input:checked ~ .checkmark
  background: var(--primary-back-color, var(--klubba-primary-back-color))
  border-color: var(--primary-back-color, var(--klubba-primary-back-color))

  .tick
    @apply opacity-100
    transform: scale(1)
</style>
