<template>
  <div class="section-footer" :class="{ 'is-loading': !!loading }">
    <div class="link" @click="goPrevPage">{{ backButton }}</div>
    <div class="next-button" :class="{ 'is-disabled': nextDisabled }" @click="goNextPage">
      <div class="next-icon">
        <div class="rounded-bottom"></div>
      </div>
    </div>
    <div class="link dummy-element">{{ backButton }}</div>
  </div>
</template>

<script>
export default {
  props: ['backButton', 'nextDisabled', 'loading'],

  methods: {
    goPrevPage() {
      this.$emit('goPrevPage');
    },
    goNextPage() {
      this.$emit('goNextPage');
    },
  },
};
</script>

<style lang="sass" scoped>
.section-footer
  @apply flex items-center justify-between
  transition: opacity var(--transition)
  &.is-loading
    opacity: .3
    pointer-events: none

.link
  @apply underline cursor-pointer leading-5 font-medium
  color: var(--primary-back-color, var(--klubba-primary-back-color))

.next-button
  @apply w-12 h-12 rounded-full flex items-center justify-center cursor-pointer
  padding-top: 2px
  transition: opacity var(--transition)
  background: var(--primary-back-color, var(--klubba-primary-back-color))
  cursor: pointer

  &.is-disabled
    opacity: 0.3
    pointer-events: none

.next-icon
  @apply w-0 h-0 rounded-radius-4
  border-left: 7px solid transparent
  border-right: 7px solid transparent
  border-top: 14px solid var(--primary-fore-color, var(--klubba-primary-fore-color))

.rounded-bottom
  @apply w-1
  height: 2px
  margin: -2px 0 0 -2px
  background: var(--primary-back-color, var(--klubba-primary-back-color))

.dummy-element
  @apply opacity-0 pointer-events-none
</style>
