<template>
  <div class="page-other page-content">
    <KlubbaRichText class="texts">
      <p><b>{{ others.filter(otherOption => otherOption.options.length > 0).length > 0 ? progress.title : progress.titleNoOptions }}</b></p>
      <p>{{ others.filter(otherOption => otherOption.options.length > 0).length > 0 ? progress.description : progress.descriptionNoOptions }}</p>
    </KlubbaRichText>

    <div class="container" v-if="others.filter(otherOption => otherOption.options.length > 0).length > 0">
      <div class="page-other-card">
        <div class="page-other-section" v-for="(other, index) in others.filter(otherOption => otherOption.options.length > 0)" :key="index">
          <p class="page-other-label">{{ other.title }}</p>
          <template v-if="other.type === 'checkbox' && other.options.length > 0">
            <klubba-checkbox
              v-for="(item, i) in other.options"
              :key="i"
              :modelValue="other.answer.includes(item)"
              @update:modelValue="$event ? other.answer.push(item) : other.answer = other.answer.filter((answer) => answer !== item)"
              :label="item"
              :value="item"
              :customClass="radioClass"
            />
          </template>
          <div v-if="others.length !== (index + 1)" class="page-other-line" />
        </div>
      </div>
    </div>

    <div class="button-wrap">
      <klubba-footer
        backButton="Back"
        @goPrevPage="prevPage"
        @goNextPage="nextPage"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import KlubbaFooter from '@/components/layout/KlubbaFooter.vue';
import KlubbaCheckbox from '@/components/default/KlubbaCheckbox.vue';
import KlubbaRichText from '@/components/default/KlubbaRichText.vue';

export default {
  components: {
    KlubbaRichText,
    KlubbaFooter,
    KlubbaCheckbox,
  },
  data() {
    return {
      progress: {
        title: 'Is there anything else to share?',
        description: 'The more information you share, the faster you can add new subscriptions.',
        titleNoOptions: "We've nothing more to ask",
        descriptionNoOptions: 'Please proceed to the next step.',
      },
      others: [
        {
          type: 'checkbox',
          title: 'Known medical conditions',
          answer: [],
          options: [],
        },
        {
          type: 'checkbox',
          title: 'Allergies',
          answer: [],
          options: [],
        },
      ],
      radioClass: '',
    };
  },
  created() {
    this.others[0].options = this.club.medical_conditions;
    this.others[0].answer = this.$store.getters.clubSpecificByKey('medical_conditions') ?? [];
    this.others[1].options = this.club.allergies;
    this.others[1].answer = this.$store.getters.clubSpecificByKey('allergies') ?? [];
  },
  computed: {
    club() {
      return this.$store.getters.club;
    },
  },
  methods: {
    ...mapMutations(['setNewUserClubSpecific']),
    prevPage() {
      this.$router.push(`/${this.club.id}/club-questions`);
    },
    nextPage() {
      const other = {
        medical_conditions: this.others[0].answer.filter((i) => i !== false),
        allergies: this.others[1].answer.filter((i) => i !== false),
      };
      this.setNewUserClubSpecific(other);
      this.$router.push(`/${this.club.id}/confirmation`);
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.container
  @apply px-8

.page-other-card
  @apply bg-white rounded-radius-10 p-5

.page-other-section
  @apply block

.page-other-label
  @apply text-sm text-black-light leading-4 tracking-spacing-7 mb-5

.page-other-line
  @apply my-5 border border-background-light

.check-class
  @apply mb-5

.button-wrap
  @apply p-8 mt-auto
</style>
